const initial = {
  tasks: {},
  taskUpdates: [],
  showConfirmCompleteDialog: false,
};

// eslint-disable-next-line default-param-last
const tasks = (state = initial, action) => {
  switch (action.type) {
    case 'TASKS_FETCH_SUCCESS': {
      const { projectId } = action;
      const newTasks = { ...state.tasks, [projectId]: action.tasks };

      return { ...state, tasks: newTasks };
    }

    case 'TASKS_SAVE_SUCCESS': {
      const { projectId, tasks } = action;
      const projTasks = { ...state.tasks[projectId], ...tasks };
      const savedTasks = { ...state.tasks, [projectId]: projTasks };

      return { ...state, tasks: savedTasks };
    }

    case 'TASK_FETCH_SUCCESS':
    case 'TASK_SAVE_SUCCESS': {
      const { projectId, task } = action;
      const projTasks = { ...state.tasks[projectId], [action.task.id]: task };
      const savedTasks = { ...state.tasks, [projectId]: projTasks };

      return { ...state, tasks: savedTasks };
    }

    case 'TASKS_COPY_SUCCESS': {
      const { projectId, copiedTasks } = action;
      const projTasks = { ...state.tasks[projectId], ...copiedTasks };
      const savedTasks = { ...state.tasks, [projectId]: projTasks };

      return { ...state, tasks: savedTasks };
    }

    case 'TASK_LINK_DELETE_SUCCESS': {
      const { projectId, taskId, linkId } = action;
      const task = state.tasks[projectId][taskId];
      const { [linkId]: value, ...otherLinks } = task.links;
      const newTask = { ...task, links: otherLinks };
      const projTasks = { ...state.tasks[projectId], [taskId]: newTask };
      const savedTasks = { ...state.tasks, [projectId]: projTasks };

      return { ...state, tasks: savedTasks };
    }

    case 'TASK_LINK_SAVE_SUCCESS': {
      const { projectId, taskId, link } = action;
      const task = state.tasks[projectId][taskId];
      const links = { ...task.links, [link.id]: link };
      const newTask = { ...task, links };
      const projTasks = { ...state.tasks[projectId], [taskId]: newTask };
      const savedTasks = { ...state.tasks, [projectId]: projTasks };

      return { ...state, tasks: savedTasks };
    }

    case 'TASK_LINK_REPLACE_SUCCESS': {
      const { projectId, taskId, previousLinkId, link } = action;
      const task = state.tasks[projectId][taskId];
      const { [previousLinkId]: replacedTask, ...otherLinks } = task.links;
      const updatedLinks = { ...otherLinks, [link.id]: link };
      const updatedTask = { ...task, links: updatedLinks };
      const projTasks = { ...state.tasks[projectId], [taskId]: updatedTask };
      const savedTasks = { ...state.tasks, [projectId]: projTasks };

      return { ...state, tasks: savedTasks };
    }

    case 'TASK_DELETE_SUCCESS': {
      const { projectId, taskId } = action;
      const { [taskId]: deletedTask, ...otherTasks } = state.tasks[projectId];
      const savedTasks = { ...state.tasks, [projectId]: otherTasks };

      return { ...state, tasks: savedTasks };
    }

    case 'MILESTONES_DELETE_SUCCESS': {
      const { projectId, milestoneId } = action;
      const { [projectId]: projectTasks, ...otherTasks } = state.tasks;

      const remainingTasks = Object.values(projectTasks).reduce((acc, task) => {
        if (task.milestone !== milestoneId) acc[task.id] = task;
        return acc;
      }, {});

      return {
        ...state,
        tasks: {
          ...otherTasks,
          [projectId]: remainingTasks,
        },
      };
    }

    case 'TASK_LAST_ACTIVITY_UPDATE': {
      const { projectId, taskId, created } = action;
      const task = state.tasks[projectId][taskId];
      const newTask = { ...task, last_action_datetime: created };
      const projTasks = { ...state.tasks[projectId], [taskId]: newTask };
      const savedTasks = { ...state.tasks, [projectId]: projTasks };

      return { ...state, tasks: savedTasks };
    }

    case 'TASK_CHECKLIST_UPDATE': {
      const { projectId, taskId, checklist_items } = action;
      const task = state.tasks[projectId][taskId];
      const newTask = { ...task, checklist_items };
      const projTasks = { ...state.tasks[projectId], [taskId]: newTask };
      const savedTasks = { ...state.tasks, [projectId]: projTasks };

      return { ...state, tasks: savedTasks };
    }

    case 'SET_TASK_UPDATE': {
      const { taskId, updateId } = action;
      const { taskUpdates } = state;
      const updatedTaskUpdates = taskUpdates.concat([{ taskId, updateId }]);

      return { ...state, taskUpdates: updatedTaskUpdates };
    }

    case 'REMOVE_TASK_UPDATE': {
      const { updateId } = action;
      const { taskUpdates } = state;
      const updatedTaskUpdates = taskUpdates.filter(update => update?.updateId !== updateId);

      return { ...state, taskUpdates: updatedTaskUpdates };
    }

    case 'TASK_COMPLETE_DIALOG_TOGGLE': {
      const { showConfirmCompleteDialog } = action;

      return {
        ...state,
        showConfirmCompleteDialog,
      };
    }

    default:
      return state;
  }
};

export default tasks;
