const persistedUI = JSON.parse(localStorage.getItem('ui'));
const persistedClientPresentationMode = persistedUI?.projectView?.clientPresentationMode;
const persistedCollapsedItems = persistedUI?.projectView?.collapsed;
const hasPersistedCollapsedItems = persistedCollapsedItems
  && Object.values(persistedCollapsedItems)?.length;
const persistedExpandedOutlines = persistedUI?.projectView?.expandedOutlines;
const hasExpandedOutlines = persistedExpandedOutlines
  && persistedExpandedOutlines?.length;

const initialState = {
  scrollMilstone: null,
  scrollPhase: null,
  selectedMilestone: null,
  selectedPhase: null,
  selectedUser: null,
  selectedTask: null,
  taskToComplete: null,
  isProjectOutlineOpen: false,
  multiEditTaskIds: [],
  dateshiftTasks: [],
  onMultiEditTaskMode: false,
  clientPresentationMode: persistedClientPresentationMode ?? false,
  timeTrackingModalProps: {
    open: false,
  },
  collapsed: hasPersistedCollapsedItems ? persistedCollapsedItems : {},
  expandedOutlines: hasExpandedOutlines ? persistedExpandedOutlines : [],
  dragging: {
    activeTaskId: null,
    activeSubtaskId: null,
    activeMilestoneId: null,
    activeOutlineMilestoneId: null,
    overMilestoneId: null,
    overPhaseId: null,
  },
  projectEditDialogSnackbar: {
    open: false,
  },
};

// eslint-disable-next-line default-param-last
const projectView = (state = initialState, action) => {
  switch (action.type) {
    case 'PROJECT_VIEW_SELECT_TASK':
      return { ...state, selectedTask: action.taskId };

    case 'PROJECT_VIEW_SELECT_MILESTONE':
      return { ...state, selectedMilestone: action.milestoneId };

    case 'PROJECT_VIEW_SELECT_PHASE':
      return { ...state, selectedPhase: action.phaseId };

    case 'PROJECT_VIEW_SELECT_USER':
      return { ...state, selectedUser: action.userId };

    case 'PROJECT_SET_SCROLL_MILESTONE': {
      return { ...state, scrollMilestone: action.scrollMilestone };
    }

    case 'PROJECT_SET_SCROLL_PHASE': {
      return { ...state, scrollPhase: action.scrollPhase };
    }

    case 'PROJECT_VIEW_ADD_MULTI_EDIT_TASKS': {
      const notIncluded = action.taskIds.filter(id => !state.multiEditTaskIds.includes(id));
      const multiEditTaskIds = state.multiEditTaskIds.concat(notIncluded);
      const onMultiEditTaskMode = multiEditTaskIds.length > 0;

      return { ...state, multiEditTaskIds, onMultiEditTaskMode };
    }

    case 'PROJECT_VIEW_REMOVE_MULTI_EDIT_TASKS': {
      const { taskIds } = action;
      const multiEditTaskIds = state.multiEditTaskIds
        .filter(selected => !taskIds.includes(selected));
      const onMultiEditTaskMode = multiEditTaskIds.length > 0;

      return { ...state, multiEditTaskIds, onMultiEditTaskMode };
    }

    case 'PROJECT_VIEW_RESET_MULTI_EDIT_TASKS':
      return { ...state, multiEditTaskIds: [], onMultiEditTaskMode: false };

    case 'PROJECT_VIEW_ADD_DATESHIFT_EDIT_TASKS': {
      const dateshiftTasks = action.taskIds;

      return { ...state, dateshiftTasks };
    }

    case 'PROJECT_VIEW_UPDATE_DATESHIFT_EDIT_TASKS': {
      return {
        ...state,
        dateshiftTasks: {
          ...state.dateshiftTasks,
          [action.taskId]: action.status,
        },
      };
    }

    case 'PROJECT_VIEW_RESET_DATESHIFT_EDIT_TASKS':
      return { ...state, dateshiftTasks: [] };

    case 'PROJECT_VIEW_SET_CLIENT_PRESENTATION_MODE':
      return { ...state, clientPresentationMode: action.clientPresentationMode };

    case 'PROJECT_VIEW_SET_TIME_TRACKING_MODAL': {
      const { timeTrackingModalProps } = action;

      return { ...state, timeTrackingModalProps };
    }

    case 'PROJECT_VIEW_EXPAND_ALL_PHASES': {
      const { projectId } = action;
      const { phases: removedPhases, ...updatedProject } = { ...state.collapsed[projectId] };
      const updatedCollapsed = { ...state.collapsed, [projectId]: updatedProject };

      return { ...state, collapsed: updatedCollapsed };
    }

    case 'PROJECT_VIEW_EXPAND_ALL_OUTLINE_PHASES': {
      const { projectId } = action;
      const { outlinePhases: removedOutlinePhases,
        ...updatedProject } = { ...state.collapsed[projectId] };
      const updatedCollapsed = { ...state.collapsed, [projectId]: updatedProject };

      return { ...state, collapsed: updatedCollapsed };
    }

    case 'PROJECT_VIEW_EXPAND_ALL_MILESTONES': {
      const { projectId } = action;
      const { milestones: removedMilestones,
        ...updatedProject } = { ...state.collapsed[projectId] };
      const updatedCollapsed = { ...state.collapsed, [projectId]: updatedProject };

      return { ...state, collapsed: updatedCollapsed };
    }

    case 'PROJECT_VIEW_EXPAND_ALL_TASKS': {
      const { projectId } = action;
      const { tasks: removedTasks, ...updatedProject } = { ...state.collapsed[projectId] };
      const updatedCollapsed = { ...state.collapsed, [projectId]: updatedProject };

      return { ...state, collapsed: updatedCollapsed };
    }

    case 'PROJECT_VIEW_REMOVE_PROJECT_FROM_COLLAPSED': {
      const { projectId } = action;
      const { [projectId]: removedProject, ...updatedCollapsed } = { ...state.collapsed };

      return { ...state, collapsed: updatedCollapsed };
    }

    case 'PROJECT_VIEW_SET_COLLAPSED_PHASES': {
      const { projectId, phases } = action;
      const updatedProject = { ...state.collapsed[projectId], phases };
      const updatedCollapsed = { ...state.collapsed, [projectId]: updatedProject };

      return { ...state, collapsed: updatedCollapsed };
    }

    case 'PROJECT_VIEW_SET_OUTLINE_COLLAPSED_PHASES': {
      const { projectId, outlinePhases } = action;
      const updatedProject = { ...state.collapsed[projectId], outlinePhases };
      const updatedCollapsed = { ...state.collapsed, [projectId]: updatedProject };

      return { ...state, collapsed: updatedCollapsed };
    }

    case 'PROJECT_VIEW_SET_COLLAPSED_MILESTONES': {
      const { projectId, milestones } = action;
      const updatedProject = { ...state.collapsed[projectId], milestones };
      const updatedCollapsed = { ...state.collapsed, [projectId]: updatedProject };

      return { ...state, collapsed: updatedCollapsed };
    }

    case 'PROJECT_VIEW_SET_COLLAPSED_TASKS': {
      const { projectId, tasks } = action;
      const updatedProject = { ...state.collapsed[projectId], tasks };
      const updatedCollapsed = { ...state.collapsed, [projectId]: updatedProject };

      return { ...state, collapsed: updatedCollapsed };
    }

    case 'PROJECT_VIEW_SET_DRAGGING_ACTIVE_TASK': {
      const { activeTaskId } = action;

      return { ...state, dragging: { ...state.dragging, activeTaskId } };
    }

    case 'PROJECT_VIEW_SET_DRAGGING_ACTIVE_SUBTASK': {
      const { activeSubtaskId } = action;

      return { ...state, dragging: { ...state.dragging, activeSubtaskId } };
    }

    case 'PROJECT_VIEW_SET_DRAGGING_ACTIVE_MILESTONE': {
      const { activeMilestoneId } = action;

      return { ...state, dragging: { ...state.dragging, activeMilestoneId } };
    }

    case 'PROJECT_VIEW_SET_DRAGGING_ACTIVE_OUTLINE_MILESTONE': {
      const { activeOutlineMilestoneId } = action;

      return { ...state, dragging: { ...state.dragging, activeOutlineMilestoneId } };
    }

    case 'PROJECT_VIEW_SET_DRAGGING_OVER_MILESTONE': {
      const { overMilestoneId } = action;

      return { ...state, dragging: { ...state.dragging, overMilestoneId } };
    }

    case 'PROJECT_VIEW_SET_DRAGGING_OVER_PHASE': {
      const { overPhaseId } = action;

      return { ...state, dragging: { ...state.dragging, overPhaseId } };
    }

    case 'PROJECT_VIEW_RESET_DRAGGING': {
      const dragging = {
        activeTaskId: null,
        activeSubtaskId: null,
        activeMilestoneId: null,
        activeOutlineMilestoneId: null,
        overMilestoneId: null,
        overPhaseId: null,
        component: null,
      };

      return { ...state, dragging };
    }

    case 'PROJECT_VIEW_SET_EXPANDED_OUTLINES': {
      return { ...state, expandedOutlines: action.outlines };
    }

    case 'PROJECT_VIEW_SET_EDIT_DIALOG_SNACKBAR': {
      const { projectEditDialogSnackbar } = action;

      return { ...state, projectEditDialogSnackbar };
    }

    case 'SELECT_TASK_TO_COMPLETE': {
      return { ...state, taskToComplete: action.taskId };
    }

    default:
      return state;
  }
};

export default projectView;
